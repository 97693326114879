import React from "react";

/**
 * Assets
 */
import './assets/styles/_index.scss';

/**
 * Components
 */
import Video from '@components/video/video';
import NoVideo from '@components/video/without-video';
import ModalVideo from '@components/video/video-modal';
import Relative from '@components/relative/relative';

const TopBanner = (props) => {
  return (
    <Relative className="top-banner">
        {props.Upload_Video != null && props.Upload_Video != "#" &&
        <Video 
            video={props.Upload_Video.publicURL}
            width="55.625%" 
            height="600px" 
            align="left" 
            contain={true}
            imgsize={props.size}
            image={props.Banner_Image} 
        />
        }
        {props.Embed_Video_URL != null && props.Embed_Video_URL != "#" &&
        <ModalVideo 
            video={props.Embed_Video_URL}
            width="55.625%" 
            height="600px" 
            align="left" 
            contain={true}
            imgsize={props.size}
            image={props.Banner_Image} 
            name=""
        />
        }
        {props.Upload_Video == null && props.Embed_Video_URL == null &&
        <NoVideo 
            width="55.625%" 
            height="600px" 
            align="left" 
            contain={true}
            imgsize={props.size}
            image={props.Banner_Image} 
        />
        }
    </Relative>
  );
}

export default TopBanner;