import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { isMobile, isTablet } from "react-device-detect";
import Modal from "react-modal"
import ModalTiny from "react-bootstrap/Modal"
import BackgroundImage from 'gatsby-background-image-es5';

/**
 * Assets
 */
import './assets/styles/_index.scss';
import Mute from './assets/img/mute.svg';

//GGFX
import {importTransformedIamges, getImageTransformedURL} from '@components/common/site/image-transformer-client';


const VideoModalEle = ({ video, name, image, autoplay, mute, align, height, width, contain, imageContain, mobileHeight, className, imgsize }) => {
  const videoRef = useRef();
  const [ isContained, setIsContained ] = useState(false);
  const [ playing, setPlaying ] = useState(false);
  const [ pause, setPause ] = useState(false);
  const [ muted, setMuted ] = useState(false);
  const [ currentHeight, setCurrentHeight ] = useState(height);
  const handlePlaying = () => {
    videoRef.current.play();
    setPlaying(!playing)
    setPause(c => !c)
  };
  const handleMute = () => setMuted(!muted);
  const handlePause = () => {
    if(pause){
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setPause(c => !c)
  };

  useEffect(() => {
    if(autoplay && !isMobile){
      setPause(c => !c)
      setPlaying((c) => !c);
    }
    if(contain){
      setIsContained(true);
    }
    if((isMobile || isTablet) && mobileHeight){
      setCurrentHeight(mobileHeight)
    } else if(isMobile) {
      setCurrentHeight('auto')
    }
  }, [autoplay, mute, contain, mobileHeight]);

  const [modalVideoOpen,setVideoOpen] = React.useState(false);
  const openVideoModal = () => {
    setVideoOpen(true);
  }
  const closeVideoModal = () => {
    setVideoOpen(false);
  }


  const [url, setUrl] = useState('')
  const image_url = image.internal.description.replace("File ","").replace('"','').replace('"','').replace(/^.*\/\/[^\/]+/, '').replace(/^\/|\/$/g, '');  

    getImageTransformedURL({
        "srcPath": image_url, 
        //output format 
        "format": 'webp',
        //transformation size
        "transform": imgsize
      }).then((result) => {
        setUrl(result)
       console.log(result);

      }).catch(error => console.log('ggfx image error', error));

  return (
  <>
    <div 
      className={classNames(`video video--${align}`, className, {'is-contained': isContained, 'is-playing': playing})} 
      style={{'height': currentHeight}}
    >

      <div 
        className={classNames('video__inner', {'contain-image': imageContain})} 
        style={{'width': isContained ? '100%' : width, backgroundImage: `url(${url ? url : image.publicURL})` }}
      >
        <span 
          className={classNames('video__play')} 
          onClick={openVideoModal}
        >
          Play
        </span>

      </div>
    </div>
    <ModalTiny show={modalVideoOpen} onHide={closeVideoModal} className="video-modal">
      <ModalTiny.Header closeButton>
        <ModalTiny.Title>{name}</ModalTiny.Title>
      </ModalTiny.Header>
      <ModalTiny.Body>
              <iframe width="100%" height="500" src={video} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyrospicture-in-picture" allowfullscreen></iframe>
      </ModalTiny.Body>
    </ModalTiny>
          </>

  )
}

export default VideoModalEle;


// Specifies the default values for props:
VideoModalEle.defaultProps = {
    imgsize: '930x1080'
};
