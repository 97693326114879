import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { isMobile, isTablet } from "react-device-detect";
import BackgroundImage from 'gatsby-background-image-es5';

/**
 * Assets
 */
import './assets/styles/_index.scss';
import Mute from './assets/img/mute.svg';

//GGFX
import {importTransformedIamges, getImageTransformedURL} from '@components/common/site/image-transformer-client';


const NoVideoEle = ({ video, image, autoplay, mute, align, height, width, contain, imageContain, mobileHeight, className, imgsize }) => {
  const [ isContained, setIsContained ] = useState(false);
  const [ playing, setPlaying ] = useState(false);
  const [ pause, setPause ] = useState(false);
  const [ muted, setMuted ] = useState(false);

  const [ currentHeight, setCurrentHeight ] = useState(height);


  useEffect(() => {
    if(contain){
      setIsContained(true);
    }
    if((isMobile || isTablet) && mobileHeight){
      setCurrentHeight(mobileHeight)
    } else if(isMobile) {
      setCurrentHeight('auto')
    }
  }, [autoplay, mute, contain, mobileHeight]);

  const [url, setUrl] = useState('')
  const image_url = image.internal.description.replace("File ","").replace('"','').replace('"','').replace(/^.*\/\/[^\/]+/, '').replace(/^\/|\/$/g, '');  

    getImageTransformedURL({
        "srcPath": image_url, 
        //output format 
        "format": 'webp',
        //transformation size
        "transform": imgsize
      }).then((result) => {
        setUrl(result)
       console.log(result);

      }).catch(error => console.log('ggfx image error', error));

  return (
    <div 
      className={classNames(`video video--${align}`, className, {'is-contained': isContained, 'is-playing': playing})}  
      style={{'height': currentHeight}}
    >
      <div 
        className={classNames('video__inner', {'contain-image': imageContain})} 
        style={{'width': isContained ? '100%' : width, backgroundImage: `url(${url ? url : image.publicURL})` }}
      ></div>
    </div>
  )
}

export default NoVideoEle;

// Specifies the default values for props:
NoVideoEle.defaultProps = {
    imgsize: '930x1080'
};
